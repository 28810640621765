import React from 'react';
import SignedInLinks from './SignedInLinks';
import SignedOutLinks from './SignedOutLinks';
import { Link } from 'react-router-dom';
import { signOut } from '../../../store/actions/authActions';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Redirect, useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
}));

const Navbar = ({ authStatus, profile, signOut }) => {
  const { isEmpty, isLoaded } = authStatus;
  const history = useHistory();

  const classes = useStyles();

  const signout2= () =>{
    signOut();
    // history.push('/#/signin');
  }

  return (
    <div className={classes.root}>
      <AppBar position='static'>
        <Toolbar>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='menu'
            component={Link}
            to='/'
          >
            <HomeIcon />
          </IconButton>
          <Typography variant='h6' color='inherit' className={classes.title}>
            Kérdőívek
          </Typography>
          {isLoaded &&
            (isEmpty ? (
              <SignedOutLinks></SignedOutLinks>
            ) : (
              <SignedInLinks
                signOut={signout2}
                profile={profile}
              ></SignedInLinks>
            ))}
        </Toolbar>
      </AppBar>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authStatus: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
