import { withStyles, makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
const drawerWidth = 240;
export default makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        bgcolor: 'primary.main',
        marginBottom: theme.spacing(4),
        minWidth: '550px',
    },
    question: {
        height: '40vh',
        textAlign: "center",
        alignContent: "center",
        backgroundColor: 'primary.main',
    },
    evaluations: {
        height: '40vh',
        textAlign: "left",
        alignContent: "left",
        backgroundColor: 'primary.main',
    },
    questionPanel:{
        textAlign: "center",
        alignContent: "center",
    },
    heading: {
        fontSize: theme.typography.pxToRem(18),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    details: {
        alignItems: 'center',
    },
    column: {
        flexBasis: '33.33%',
    },
    button: {
        margin: theme.spacing(1),
    },
    error: {
        color: '#eaa',
    },
    containedGreen: {
        color: '#fff',
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
            '@media (hover: none)': {
                backgroundColor: green[500],
            },
        },
    },
    evaluationDescription: {
        color: '#000',
        backgroundColor: green[100],
        '&:hover': {
            backgroundColor: green[200],
            '@media (hover: none)': {
                backgroundColor: green[200],
            },
        },
    },
    containedRed: {
        color: '#fff',
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700],
            '@media (hover: none)': {
                backgroundColor: red[500],
            },
        },
    },

}));
