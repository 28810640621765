import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import FillQuestionnaire from './FillQuestionnaire';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        bgcolor: 'primary.main',
        marginBottom: theme.spacing(4),
    },
    question: {
        height: '40vh',
        textAlign: "center",
        alignContent: "center",
        backgroundColor: 'primary.main',
    },
    questionPanel:{
        textAlign: "center",
        alignContent: "center",
    },
    heading: {
        fontSize: theme.typography.pxToRem(18),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    details: {
        alignItems: 'center',
    },
    column: {
        flexBasis: '33.33%',
    },
    button: {
        margin: theme.spacing(1),
    },
    error: {
        color: '#eaa',
    },
    containedGreen: {
        color: '#fff',
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
            '@media (hover: none)': {
                backgroundColor: green[500],
            },
        },
    },
    containedRed: {
        color: '#fff',
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700],
            '@media (hover: none)': {
                backgroundColor: red[500],
            },
        },
    },
}));

const FillQuestionnaireMain = (props) => {
    const classes = useStyles();
    const { authStatus } = props;
    const { isEmpty, isLoaded } = authStatus;
    return (

        <Container maxWidth='sm'>
            <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                mt='1.5rem'
            >
                {isLoaded &&
                    (isEmpty ? (
                        <Redirect to='/signin' />
                    ) : (
                        <FillQuestionnaire
                            match={props.match}
                            classes={classes}
                        />
                    )
                    )
                }
            </Box>
        </Container>
    );
};


const mapStateToProps = (state) => {
    return {
        questionnaires: state.firestore.ordered.questionnaires,
        authStatus: state.firebase.auth,
    };
};


export default connect(mapStateToProps)(FillQuestionnaireMain);
