import React from 'react';
import Button from '@material-ui/core/Button';
import { useHotkeys } from 'react-hotkeys-hook';
let e = true;
const AnswerButtons = (params) => {
    const { max, current, last, answers } = params;
    const { classes } = params;
    useHotkeys('f', () => answerquestion(0))
    useHotkeys('j', () => answerquestion(1))
    const answerquestion = (a) => {
        if (!params.loading)
            params.answerQuestion(a)
    }
    return (
        <div>
            {answers[current]}
            <Button
                disabled={params.loading}
                variant='contained'
                className={classes.button}
                onClick={(e) => { params.answerQuestion(0) }}
                color={answers[current] == 0 ? 'primary' : 'contained'}
            >
                Nem[f]
            </Button>
            <Button
                disabled={params.loading}
                variant='contained'
                className={classes.button}
                color={answers[current] == 1 ? 'primary' : 'contained'}
                onClick={(e) => { params.answerQuestion(1) }}
            >
                Igen[j]
            </Button>
        </div>
    );
};

export default AnswerButtons;