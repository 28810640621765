import React, { Component } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { signOut } from '../../../store/actions/authActions';
import IconButton from '@material-ui/core/IconButton';
import Apps from '@material-ui/icons/Apps';
import FormatListBulleted from '@material-ui/icons/FormatListBulleted';
import { Tooltip } from '@material-ui/core';
import ToolBarIcon from './ToolBarIcon';
import App from '../../../App';
import { deleteQuestionnaire } from '../../../store/actions/questionnaireActions';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Questionnaire from '../projects/Questionnaire';
import QuestionairesTable from './QuestionairesTable';
import Breadcrumb from '../../common/Breadcrumb';


class QuestionnairesView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            values: {
                view: 'box',
                example: 'filter'
            }
        };
    }
    changeView(field, value) {
        let new_value = { ...this.state.values }
        new_value[field] = value;
        if (this.state.values[field] !== value) {
            this.setState(
                {
                    values: new_value
                }
            );
            this.props.changeView(new_value);
        }

    }
    render() {
        const { questionnaires, path, classes, filled, sampling, samplingId,filledSampling } = this.props;
        return (
            <div>
                {/* <Breadcrumb classes={classes} path={path} /> */}
                {/* <Toolbar>
                    <ToolBarIcon
                        classes={classes}
                        values={this.state.values}
                        changeView={(field, value) => { this.changeView(field, value) }}
                        icon='Apps'
                        group='view'
                        value='box'
                        title='Doboz nézet'
                    />
                    <ToolBarIcon
                        classes={classes}
                        values={this.state.values}
                        changeView={(field, value) => { this.changeView(field, value) }}
                        icon='FormatListBulleted'
                        group='view'
                        value='list'
                        title='List nézet '
                    />
                </Toolbar> */}
                <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                    mt='1.5rem'
                >
                    {this.state.values.view === 'box' ?
                        questionnaires &&
                        questionnaires.map((questionnaire) => {
                            return <Questionnaire
                                questionnaire={questionnaire}
                                filled={filled}
                                filledSampling={filledSampling}
                                deleteQuestionnaire={deleteQuestionnaire}
                                key={questionnaire.id}
                                sampling={sampling}
                                samplingId={samplingId}
                                classes={this.props.classes}
                                path={this.props.path}
                            />;
                        })

                        : <QuestionairesTable
                            questionnaires={questionnaires}
                            filled={filled}
                            sampling={sampling}
                            classes={this.props.classes}
                            path={this.props.path}
                        />
                    }

                </Box>
            </div >
        );
    }
}

export default QuestionnairesView;