import React, { Component } from 'react';
import QuestionnaireItem from './QuestionnaireItem';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    margin: {
        marginTop: theme.spacing(2),
    },
    marginButton: {
        marginTop: theme.spacing(4),
    },
    rounded: {
        color: '#fff',
        backgroundColor: green[500],
        marginTop: theme.spacing(4),
    },
    select: {
        marginTop: theme.spacing(2),
        fontSize: '16px',
    },
    circularProgressWhite: {
        color: '#fff',
    },
}));


class QuestionnaireItems extends Component {
    constructor(props) {
        let arr = [];
        Object.keys(props.items).forEach(key => arr.push(props.items[key]))
        super(props);
        this.state = {
            items: arr,
            email: 'xcc'
        };
        this.addItem = this.addItem.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
    }
    addItem = (e) => {
        e.preventDefault();
        let a = this.state.email;
        this.setState((prevState) => {
            return {
                items: prevState.items.concat({ type: 1, question: this.state.email, children: [] })
            };
        });
        this.props.update([...this.state.items, { type: 1, question: this.state.email, children: [] }])
        // if (this._inputElement.value !== "") {
        //     var newItem = {
        //         text: this._inputElement.value,
        //         key: Date.now()
        //     };

        //     this.setState((prevState) => {
        //         return {
        //             items: prevState.items.concat(newItem)
        //         };
        //     });

        //     this._inputElement.value = "";
        // }
    };
    deleteItem(key) {
        var filteredItems = this.state.items.filter(function (item, index) {
            return (index !== key);
        });

        this.setState({
            items: filteredItems
        });
        this.props.update(filteredItems)
    }
    handleEmailChange(e) {
        this.setState({ email: e.target.value });
    }

    render() {
        return (
            <div>
                <h2>Kérdések</h2>
                <form onSubmit={this.addItem}>
                    <input
                        // className={classes.margin}
                        placeholder="kérdéscsoport"
                        onChange={this.handleEmailChange}>
                    </input>
                    <Button
                        variant='contained'
                        color='primary'
                        startIcon={<CreateIcon />}
                        onClick={this.addItem}
                    >
                        Új kérdéscsoport
                    </Button>
                </form>
                <div>
                    {this.props.items.map((h, index) => {
                        return (
                            <QuestionnaireItem
                                key={index}
                                index={index}
                                question={h}
                                deleteItem={this.deleteItem}
                            />

                        );
                    })}
                </div>
            </div>
        );
    }
}

export default QuestionnaireItems;