import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Navbar from './components/UI/navigation/Navbar';
import AddQuestionnaire from './components/UI/projects/AddQuestionnaire';
import UpdateQuestionnaire from './components/UI/projects/UpdateQuestionnaire';
import Dashboard from './components/UI/dashboard/Dashboard';
import SignIn from './components/UI/auth/SignIn';
import SignUp from './components/UI/auth/SignUp';
import FillQuestionnaire from './components/UI/projects/FillQuestionnaire';
import FillQuestionnaireMain from './components/UI/projects/FillQuestionnaireMain';
import Sampling from './components/UI/dashboard/Sampling';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { green, blueGrey } from '@material-ui/core/colors';
import ResetPassword from './components/UI/auth/ResetPassword';

const outerTheme = createMuiTheme({
  palette: {
    primary: {
      main: blueGrey[500],
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={outerTheme}>
      <Router
        basename={'#'}
      >
        <Switch>
          <Route path={["/signin", "/signup", "/resetpassword"]}>
            <Switch>
              <Route exact path='/signin' component={SignIn} />
              <Route exact path='/signup' component={SignUp} />
              <Route exact path='/resetpassword' component={ResetPassword} />
            </Switch>
          </Route>
          <Route path={["/", "/fill"]}>
            <Navbar />
            <Switch>
              <Route exact path='/' component={Dashboard} />
              <Route exact path='/signin' component={SignIn} />
              <Route exact path='/signup' component={SignUp} />
              <Route exact path='/add' component={AddQuestionnaire} />
              <Route exact path='/fill/:id' component={FillQuestionnaireMain} />
              <Route exact path='/sampling/:id' component={Sampling} />
              <Route exact path='/updateQuestionnaire/:id' component={UpdateQuestionnaire} />
            </Switch>
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>

  );
}

function PrivateRoute({ children, ...rest }) {
  let auth = false;//useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        true ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
export default App;
