import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { signUp } from '../../../store/actions/authActions';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';

const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(2),
  },
  marginButton: {
    marginTop: theme.spacing(4),
  },
  rounded: {
    color: '#fff',
    backgroundColor: green[500],
    marginTop: theme.spacing(4),
  },
  standardError: {
    width: '100%',
    marginTop: theme.spacing(5),
  },
  circularProgressWhite: {
    color: '#fff',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignUp = ({ userStatus, authStatus, signUp }) => {
  const { isEmpty, isLoaded } = authStatus;
  const { loading, error } = userStatus;

  const classes = useStyles();

  const [credentials, setCredentials] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    e.preventDefault();
    setCredentials({ ...credentials, [e.target.id]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    signUp(credentials);
  };

  if (isLoaded && !isEmpty) return <Redirect to='/' />;

  if (isLoaded && isEmpty) {
    return (
      <Grid container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh' }}
        margin="2rem"
      >
        <CssBaseline />
        <div className={classes.paper}>
          <Box display='flex' flexDirection='column' alignItems='center'>
            <Avatar className={classes.avatar}>
              <PersonAddIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Regisztráció
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <Box
              display='flex'
              flexDirection='row'
              flexWrap='wrap'
              alignItems='center'
              justifyContent='space-between'
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id='lastName'
                label='Vezetéknév'
                value={credentials.lastName}
                onChange={handleChange}
                className={classes.margin}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id='firstName'
                label='Keresztnév'
                value={credentials.firstName}
                onChange={handleChange}
                className={classes.margin}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                type='email'
                id='email'
                label='Email'
                fullWidth
                value={credentials.email}
                onChange={handleChange}
                className={classes.margin}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                required
                id='password'
                label='jelszó'
                type='password'
                autoComplete='current-password'
                value={credentials.password}
                onChange={handleChange}
                className={classes.margin}
              />
              <Button
                fullWidth
                type='submit'
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {loading ? (
                  <CircularProgress className={classes.circularProgressWhite} />
                ) : (
                  'Regisztráció'
                )}
              </Button>
              {error ? (
                <Alert
                  variant='filled'
                  severity='error'
                  className={classes.standardError}
                >
                  Invalid credentials
                </Alert>
              ) : null}
            </Box>
          </form>

        </div>
        <Grid container>
          <Grid item xs>
            <Link href="#" variant="body2">
            </Link>
          </Grid>
        </Grid>
        <Box mt={8}>
          <Link href="/#/signin" variant="body2">
            <div>{"Bejelentkezés"}</div>
          </Link>
        </Box>
      </Grid >
    );
  } else return <></>;
};

const mapStateToProps = (state) => {
  return {
    userStatus: state.auth,
    authStatus: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (credentials) => dispatch(signUp(credentials)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
