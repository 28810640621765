import React, { Component } from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Divider from '@material-ui/core/Divider';
import { HelpOutline } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import AnswerButtons from './AnswerButtons';
import NavigationButtons from './NavigationButtons';
import Summary from './Summary';
import Guide from './Guide';
import { IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';

class Questions extends Component {
    constructor(props) {
        super(props);
        this.prevQuestion = this.prevQuestion.bind(this);
        this.nextQuestion = this.nextQuestion.bind(this);
        this.answerQuestion = this.answerQuestion.bind(this);
        this.handleHideGuide = this.handleHideGuide.bind(this);
        const { fill, questionnaire } = this.props;
        let sections = questionnaire ? questionnaire.questionnaire : [];
        let answers = fill && fill.answers ? fill.answers : [];
        sections.map((section) => (section.length));
        let max = 0;
        sections.forEach(q => (max += q.children.length));
        let questionlist = [];
        sections.forEach(q => (
            q.children.forEach(x => (
                questionlist[x.id] = x.name
            ))
        ));
        let answer = [];
        answer[2] = 0;
        answer[3] = 1;
        answer[1] = 1;
        this.state = {};
        max = Object.keys(questionlist).pop();
        let last = Object.keys(answers).length > 0 ? parseInt(Object.keys(answers).pop()) + 1 : 1;
        // last = last > max ? max : last;
        this.state = {
            fill: fill,
            questionnaire: questionnaire,
            sections: sections,
            answers: answers,
            max: max,
            current: last,
            last: last,
            questionlist: questionlist,
            showGuide: true,
        };
    }
    nextQuestion(e) {
        if (this.state.current < this.state.last) {
            this.setState(
                {
                    current: this.state.current + 1
                }
            );
        }
    }
    prevQuestion(e) {
        if (this.state.current > 1) {
            this.setState(
                {
                    current: this.state.current - 1
                }
            );
        }
    }

    answerQuestion(answer) {
        if (this.props.firstMissingAnswer < this.state.current) {
            this.setState(
                {
                    current: this.props.firstMissingAnswer,
                    // last: this.props.firstMissingAnswer-1,
                })
        } else {
            if (this.state.current <= this.state.max) {
                let a = { ...this.state.answers };
                a[this.state.current] = answer;
                this.setState(
                    {
                        answers: a,
                        current: Math.min(this.state.current + 1, Object.keys(a).length + 1),
                        last: parseInt(Object.keys(a).pop()) + 1
                    }
                );
                this.props.updateAnswers(a, this.props.fill);
            }
        }
    }
    handleHideGuide() {
        this.setState({ showGuide: false });
    }

    handleShowGuide() {
        this.setState({ showGuide: true });
    }
    componentDidUpdate(prevProps) {
        const { fill, questionnaire, firstMissingAnswer } = this.props;
        let sections = questionnaire ? questionnaire.questionnaire : [];
        let answers = fill && fill.answers ? fill.answers : [];
        sections.map((section) => (section.length));
        let max = 0;
        sections.forEach(q => (max += q.children.length));
        let questionlist = [];
        sections.forEach(q => (

            q.children.forEach(x => (
                questionlist[x.id] = x.name
            ))
        ));
        let answer = [];
        answer[2] = 0;
        answer[3] = 1;
        answer[1] = 1;
        let last = Object.keys(answers).length > 0 ? Object.keys(answers).pop() : 1;
        max = Object.keys(questionlist).pop();
        // if (this.props.firstMissingAnswer > max) {
        //     this.props.finishEvaluations(this.props.fill);
        // }
        if (this.props.questionnaire !== prevProps.questionnaire || this.props.fill !== prevProps.fill) {
            this.setState({
                fill: fill,
                questionnaire: questionnaire,
                sections: sections,
                answers: answers,
                max: max,
                current: this.state.current > max ? max : this.state.current,
                last: this.state.last > max ? max : this.state.last,
                questionlist: questionlist
            });
        }
    }
    render() {
        const { firstMissingAnswer, classes, signOut, fill, questionnaire, updateEvaluations } = this.props;
        return (
            <form>

                {firstMissingAnswer > this.state.max ?
                    <Summary
                        classes={classes}
                        signOut={signOut}
                        filled={fill}
                        questionnaire={questionnaire}
                        updateEvaluations={updateEvaluations}
                    />
                    : (
                        this.state.showGuide ?
                            <Guide
                                handleHideGuide={this.handleHideGuide}
                                text={questionnaire.guide}
                                open={this.state.showGuide}
                            />
                            :
                            <ExpansionPanel
                                expanded={true}
                                className={classes.questionPanel}
                            >
                                <h5>
                                    {this.state.current}/{this.state.max}
                                    <IconButton color="primary" aria-label="Információ" size="small"
                                        onClick={() => {
                                            this.handleShowGuide()
                                        }}
                                    >
                                        <HelpOutline fontSize="inherit" />
                                    </IconButton>
                                </h5>
                                <div
                                    // className={classes.question}
                                    aria-controls='panel1c-content'
                                    id='panel1c-header'
                                >

                                    <h2 className={classes.question}>
                                        {this.state.questionlist &&
                                            this.state.questionlist[this.state.current]}
                                    </h2>
                                </div>

                                <AnswerButtons
                                    answerQuestion={this.answerQuestion}
                                    classes={classes}
                                    max={this.state.max}
                                    current={this.state.current}
                                    last={this.state.last}
                                    answers={this.state.answers}
                                />
                                <ExpansionPanelDetails >
                                </ExpansionPanelDetails>
                                <Divider />
                                <ExpansionPanelActions>
                                    <NavigationButtons
                                        classes={classes}
                                        max={this.state.max}
                                        current={this.state.current}
                                        last={this.state.last}
                                        prevQuestion={this.prevQuestion}
                                        nextQuestion={this.nextQuestion}
                                    />
                                </ExpansionPanelActions>
                            </ExpansionPanel>
                    )
                }
            </form>
        );
    }
}

export default Questions;