import React from 'react';
import Button from '@material-ui/core/Button';

const NavigationButtons = (params) => {
    const { max, current, last } = params;
    const { classes } = params;
    return (
        <div>
            {last === max &&
            <Button
                variant='contained'
                className={classes.button}
                color='secondary'
                onClick={(e) => { params.nextQuestion(e) }}
            >
                Kérdőív kitöltésének befejezése
            </Button>
            }
            <Button
                disabled = {current<2}
                variant='contained'
                className={classes.button}
                onClick={(e) => { params.prevQuestion(e) }}
            >
                Vissza
            </Button>
            <Button
                disabled = {current>=last}
                variant='contained'
                className={classes.button}
                // color='primary'
                onClick={(e) => { params.nextQuestion(e) }}
            >
                Előre
            </Button>
        </div>
    );
};

export default NavigationButtons;