import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';
import { useHistory } from 'react-router-dom';
import { MobileStepper, Step, StepButton, Stepper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardArrowLeftOutlined, KeyboardArrowRightOutlined } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Evaluation from './Evaluation';
import EvaluationApi from './EvaluationApi';
import Sampling from '../dashboard/Sampling';

const Summary = (params) => {
    const { classes, filled, questionnaire, updateEvaluations } = params;
    console.log(params);
    const history = useHistory();
    const evaluate = () => {
        let result = {};
        let params = [];
        if (questionnaire.scales.api) {
            result[1] = '';
            let values = '';
            Object.keys(filled.answers).forEach(key => {
                values = values + filled.answers[key].toString();
            });
            params['id'] = values;
            Object.keys(questionnaire.scales.parameters).forEach((key) => {
                params[questionnaire.scales.parameters[key]] = filled.personal[key];
            });
            var url = new URL(questionnaire.scales.api);
            Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
            // API
            console.log(url)
            fetch(url)
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log('result', result);
                        updateEvaluations(result, filled.id);
                    },
                    (error) => {
                        console.log('ERROR', error);

                    }
                )
        } else {
            questionnaire.scales.forEach(scale => {
                let value = 0;
                // evaluation scales
                let sub_result = {};
                scale.children.forEach(sub => {
                    let s = 0;
                    // evaluation subscales
                    sub.values.forEach(value => {
                        s += filled.answers[value];
                    });
                    // alskála értéke
                    value += s;
                    sub_result[sub.id] = {
                        value: s
                    };
                });

                // evaluation 
                let evaluation = {};
                scale.evaluation.forEach(e => {
                    if (e.min <= value && value <= e.max) {
                        evaluation = e;
                    }
                });
                result[scale.id] = {
                    value: value,
                    evaluation: evaluation,
                    sub_scales: sub_result

                };
            });
            updateEvaluations(result, filled.id)
        }
    }
    let questionList = [];
    let questionNumber = 0;
    if (questionnaire.questionnaire) {
        questionnaire.questionnaire.forEach(sections => (
            sections.children.forEach(question => (
                questionList[question.id] = question.name
            ))
        ));
        questionNumber = Object.keys(questionList).pop();
    }
    let personal = [];
    if (questionnaire.personal) {
        questionnaire.personal.forEach(p => (
            personal[p.id] = p
        ));
    }
    // let maxSteps = 0;
    // let steps1 = [];
    // if (filled && filled.evaluations && Array.isArray(questionnaire.scales)) {
    //     steps1 = Object.keys(filled.evaluations).map((key, index) =>
    //     ({
    //         label: questionnaire.scales.filter(x => x.id == key)[0].name,
    //         value: filled.evaluations[key].evaluation.value,
    //         evaluation: filled.evaluations[key].evaluation.name,
    //         level: filled.evaluations[key].value,
    //         description: questionnaire.scales.filter(x => x.id == key)[0].description,
    //         sub: Object.keys(filled.evaluations[key].sub_scales).map((key2) => (
    //             {
    //                 name: questionnaire.scales.filter(x => x.id == key)[0].children.filter(x => x.id == key2)[0].name,
    //                 value: key2
    //             }
    //         ))
    //     }
    //     ));
    //     console.log('.................', steps1);
    //     maxSteps = steps1.length;
    // }



    return (
        <div>
            {filled && filled.status == 'in progress' &&
                <div>
                    <h1 className={classes.question}>
                        Köszönjük, hogy kitöltötte a kérdőívet!
                    </h1>
                    <div>
                        <Button
                            variant='contained'
                            color='primary'
                            className={classes.button}
                            startIcon={<CreateIcon />}
                            onClick={(e) => { evaluate() }}
                        >
                            Kérdőív eredmények megtekintése
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            className={classes.button}
                            startIcon={<CreateIcon />}
                            onClick={() => {
                                history.push(`/`);
                            }}
                        >
                            Vissza a tesztekhez
                        </Button>
                    </div>
                </div>
            }
            <div>
                {filled && filled.evaluations && Array.isArray(questionnaire.scales) && filled.docId == questionnaire.id &&
                    <>
                        <Button
                            variant='contained'
                            color='primary'
                            className={classes.button}
                            startIcon={<CreateIcon />}
                            onClick={() => {
                                history.push(`/`);
                            }}
                        >
                            Vissza a tesztekhez
                        </Button>
                        <Evaluation
                            filled={filled}
                            questionnaire={questionnaire}
                            classes={classes}
                        />
                    </>
                }
                {filled && filled.evaluations && questionnaire.scales.api && filled.docId == questionnaire.id &&
                    <>
                        <Button
                            variant='contained'
                            color='primary'
                            className={classes.button}
                            startIcon={<CreateIcon />}
                            onClick={() => {
                                history.push(`/`);
                            }}
                        >
                            Vissza a tesztekhez
                        </Button>

                        <EvaluationApi
                            filled={filled}
                            questionnaire={questionnaire}
                            classes={classes}
                        />
                    </>
                }
            </div>
        </div>
    );
};

export default Summary;