import React from 'react';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Gdpr = (params) => {
    const { classes, history } = params;
    return (
        <div className={classes.root}>
            <div className={classes.root}>
                <ExpansionPanel defaultExpanded>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel1c-content'
                        id='panel1c-header'
                    >
                        <div className={classes.column}>
                            <Typography noWrap display='block' >
                                Felhasználási feltételek
                            </Typography>
                        </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.details}>
                        <Typography>
                            <div>
                                {params.questionnaires &&
                                    params.questionnaires.gdpr
                                }
                            </div>
                        </Typography>
                    </ExpansionPanelDetails>
                    <Divider />
                    <ExpansionPanelActions>
                        <Button
                            variant='contained'
                            className={classes.button}
                            onClick={() => {
                                history.push(`/`);
                            }}
                        >
                            Mégse
                        </Button>
                        <Button
                            variant='contained'
                            className={classes.button}
                            color='primary'
                            onClick={(fill) => params.acceptGdpr(params.fill)}
                        >
                            Elfogadom a feltételeket
                        </Button>
                    </ExpansionPanelActions>
                </ExpansionPanel>
            </div>
        </div>
    );
};

export default Gdpr;