import firebase from 'firebase/app';
import * as actions from './actionTypes';

export const addQuestionnaire = (questionnaire) => async (dispatch, getState) => {
  try {
    dispatch({ type: actions.ADD_TODO_START });
    const uid = getState().firebase.auth.uid;
    const firestore = firebase.firestore();
    await firestore.collection('questionnaires').add({
      ...questionnaire,
      authorId: uid,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
    dispatch({ type: actions.ADD_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.ADD_TODO_FAIL, err });
  }
};

export const updateQuestionnaire = (questionnaire, id) => async (dispatch) => {
  try {
    dispatch({ type: actions.UPDATE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection('questionnaires').doc(id).update({
      title: questionnaire.title,
      description: questionnaire.description,
      gdpr: questionnaire.gdpr,
      personal: questionnaire.personal,
      version: questionnaire.version,
      questionnaire: questionnaire.questionnaire,
      state: questionnaire.state,
      updatedAt: new Date(),
    });
    dispatch({ type: actions.UPDATE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.UPDATE_TODO_FAIL, err });
  }
};

export const deleteQuestionnaire = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.DELETE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection('questionnaires').doc(id).delete();
    dispatch({ type: actions.DELETE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.DELETE_TODO_FAIL, err });
  }
};
