import { Chip, ListItemText, MenuItem, OutlinedInput, TextField } from '@material-ui/core';
import React from 'react';
import { CheckBox } from '@material-ui/icons';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import Multiselect from 'multiselect-react-dropdown';

const PersonalField = (params) => {
    const { val, meta, form, handleChange, handleChangeArray, errors, classes } = params;
    const handleFieldChange = event => {
        handleChangeArray(event.target.name, event.target.value)
        event.persist();
        // setFormState(formState => ({
        //   ...formState,
        //   [event.target.name]:
        //     event.target.type === "checkbox"
        //       ? event.target.checked
        //       : event.target.value
        // }));
    };
    const onSelect = event => {
        let a = event.map((i) => i.id);
        handleChangeArray(form.id, a)
        // handleChangeArray(event.target.name, event.target.value)
        // event.persist();
        // setFormState(formState => ({
        //   ...formState,
        //   [event.target.name]:
        //     event.target.type === "checkbox"
        //       ? event.target.checked
        //       : event.target.value
        // }));
    };
    const onRemove = event => {
        let a = event.map((i) => i.id);
        handleChangeArray(form.id, a)
    }
    const handleChange2 = (event) => {
        const {
            target: { value },
        } = event;
        handleChangeArray(form.id, event.target.value)
        // setPersonName(
        //   // On autofill we get a the stringified value.
        //   typeof value === 'string' ? value.split(',') : value,
        // );
    };

    return (
        <div>
            {/* {form.type == 1 &&
                <div>
                    {form.type}
                    {form.q2}
                    <TextField id="standard-basic" label="Standard" />
                </div>
            } */}
            {form.type == 3 &&
                <div>
                    <div>
                        {form.q2}
                    </div>
                    <TextField
                        required
                        id={"persid" + form.id}
                        name={form.id}
                        select
                        label={form.label}
                        // ref={ref.inputRef}
                        // value={currency}
                        onChange={handleChange}
                        helperText={form.helper}
                    >
                        {form.values.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
            }
            {form.type == 2 &&
                <div>
                    <div>
                        {form.q2}
                    </div>

                    <TextField
                        required={form.required ? true : false}
                        id={"persid" + form.id}
                        name={form.id}
                        label={form.label}
                        onChange={handleChange}
                    // ref={ref.inputRef}
                    />
                </div>
            }
            {form.type == 4 &&
                <div>
                    <div>
                        {form.q2}
                    </div>

                    <TextField
                        required={form.required ? true : false}
                        id={"persid" + form.id}
                        name={form.id}
                        type="number"
                        inputProps={{ min: form.min, max: form.max, step: "1" }}
                        label={form.label}
                        onChange={handleChange}
                    // ref={ref.inputRef}
                    />
                </div>
            }
            {form.type == 5 &&
                <div>
                    <div>
                        {form.q2}
                    </div>
                    {/* <TextField
                        required
                        id={"persid" + form.id}
                        name={form.id}
                        select
                        SelectProps={{
                            multiple: true,
                            value: val[form.id] ?? [],
                            onChange: handleFieldChange
                        }}
                        label={form.label}
                        // ref={ref.inputRef}
                        // value={currency}
                        // onChange={handleChange}
                        helperText={form.helper}
                    >
                        {meta[form.model] && meta[form.model].map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                                {val[form.id] ? val[form.id].indexOf(option.id) > -1 : false}
                            </MenuItem>
                        ))}
                    </TextField> */}
                    {/* <Select
                        required
                        labelId="demo-multiple-name-label"
                        id={"persid" + form.id}
                        multiple
                        value={val[form.id] ? val[form.id] : []}
                        onChange={handleChange2}
                    //  input={<OutlinedInput label="Name" />}
                    // input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    // renderValue={(selected) => (
                    //     <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    //       {selected.map((value) => (
                    //         <Chip key={value} label={meta[form.model][value]} />
                    //       ))}
                    //     </Box>
                    //   )}
                    // MenuProps={MenuProps}
                    >
                        {meta[form.model] && meta[form.model].map((option) => (
                            <MenuItem
                                key={option.id}
                                value={option.id}
                            // style={getStyles(name, personName, theme)}
                            >
                                {option.name}

                            </MenuItem>
                        ))}
                    </Select> */}
                    <Multiselect
                        id={"persid" + form.id}
                        placeholder={form.q2}
                        singleSelect
                        options={meta[form.model]} // Options to display in the dropdown
                        selectedValues={val[form.id] ? meta[form.model].filter(function (item, index) {
                            return (val[form.id].indexOf(item.id) > -1);
                        }) : []}
                        // {val[form.id]} // Preselected value to persist in dropdown
                        onSelect={onSelect} // Function will trigger on select event
                        onRemove={onRemove} // Function will trigger on remove event
                        displayValue="name" // Property name to display in the dropdown options
                    // isObject={false}
                    />
                </div>
            }
            {errors.length > 0 && form.required &&
                <span className={classes.error}>{errors}</span>
            }
        </div>
    );
};

export default PersonalField;