import React from 'react';
import { connect } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { deleteQuestionnaire } from '../../../store/actions/questionnaireActions';
import Container from '@material-ui/core/Container';
import useStyles from "../../common/styles";
import convertArrayByField from '../../common/utils';
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';
import { finishEvaluations, updateGdpr, updatePersonal, addFilledquestionnaire, updateAnswers, updateEvaluations } from '../../../store/actions/filledQuestionnaireActions';
import Personal from '../projects/Personal';
import Questions from '../projects/Questions';
import Gdpr from '../projects/Gdpr';
import Box from '@material-ui/core/Box';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import { Redirect, useHistory } from 'react-router-dom';
import Summary from '../projects/Summary';

const Sampling = (params) => {
    const { finishEvaluations, institutions, updateEvaluations, updateStatus, updateGdpr, updatePersonal, addFilledquestionnaire, updateAnswers, addFilledquestionnaire2, match, sampling, users, questionnaires, authStatus, userStatus, filledquestionnaires } = params;
    const { isEmpty, isLoaded } = authStatus;
    const { loading } = updateStatus;
    const classes = useStyles();
    const path = [{ name: 'Kérdőívek', path: '/' }];
    const history = useHistory();
    let filledquestionnairesSamplingArray = convertArrayByField(filledquestionnaires, "samplingId");
    let questionnairesArray = convertArrayByField(questionnaires, "id");
    let institutionsArray = convertArrayByField(institutions, "id");
    const samplingId = params.match.params.id;
    const activeSampling =
        sampling && sampling[0]
            ? sampling[0]
            : false;
    const activeFilledQuestinnare =
        filledquestionnairesSamplingArray &&
            params.match.params.id &&
            filledquestionnairesSamplingArray[params.match.params.id] &&
            filledquestionnairesSamplingArray[params.match.params.id][0]
            ? filledquestionnairesSamplingArray[params.match.params.id][0]
            : false
    const answers = activeFilledQuestinnare && activeFilledQuestinnare.answers ? activeFilledQuestinnare.answers : false;
    const aa = Object.keys(answers).map((a) => (parseInt(a)));
    const max = Math.max(...aa);
    const sum = Object.keys(answers).length;
    const activeQuestinnare =
        activeSampling &&
            questionnairesArray[activeSampling.name] &&
            questionnairesArray[activeSampling.name][0]
            ? questionnairesArray[activeSampling.name][0]
            : false
    let firstMissingAnswer = 1;
    if (activeFilledQuestinnare && activeFilledQuestinnare.answers && activeQuestinnare && activeQuestinnare.questionnaire) {
        let sections = activeQuestinnare.questionnaire;
        // let answers = fill && fill.answers ? fill.answers : [];
        let questionlist = [];
        sections.forEach(q => (
            q.children.forEach(x => (
                questionlist.push(parseInt(x.id))
            ))
        ));
        var filteredItems = questionlist.filter(function (item, index) {
            return (!aa.includes(item));
        });
        firstMissingAnswer = filteredItems[0] == undefined ? questionlist.length + 1 : filteredItems[0]
    }

    useFirestoreConnect([
        {
            collection: 'questionnaires',
            // doc:  activeFilledQuestinnare.name,
            where: [
                ['public', '==', true],
            ],
        },
        {
            collection: "sampling",
            doc: params.match.params.id,
            storeAs: 'sampling'
        },
        {
            collection: "filledquestionnaires",
            where: [
                ['authorId', '==', userStatus.uid || 'xxx']
            ],
        },
        {
            collection: "users",
            doc: authStatus.uid,
        },
        {
            collection: "institutions",
            storeAs: 'institutions'
        }

    ]);

    const addFilledQuestionnaire = (e) => {

        addFilledquestionnaire2({
            docId: activeQuestinnare.id,
            title: activeQuestinnare.title,
            samplingId: activeSampling.id,
            status: 'starting',
            gdpr: false,
            personal: [],
            answers: []
        });
    }
    const acceptGdpr = (fill) => {
        updateGdpr(true, fill.id);
    }

    const acceptPersonal = (val, fill) => {
        updatePersonal(val, fill.id);
    }

    const updateAnswer = (val, fill) => {
        updateAnswers(val, fill.id);
    }

    return (
        <Container maxWidth='lg'>
            {isLoaded && isEmpty && (
                <Redirect to='/signin' />
            )}
            <Box alignItems='center'>
                {/* <div>Teszt:{activeSampling && activeSampling.om ? activeSampling.om : 'Hiányzó mintavétel'}</div>
                <div>Mintavétel:{activeSampling && activeSampling.om ? activeSampling.om : 'Hiányzó mintavétel'}</div>
                <div>Kitöltött teszt:{activeFilledQuestinnare && activeFilledQuestinnare.title && activeFilledQuestinnare.title + activeFilledQuestinnare.id}</div>
                <div>Státusz:{activeFilledQuestinnare && activeFilledQuestinnare.status}</div>
                <div>Válaszok száma:{sum}</div>
                <div>Legutolsó válasz:{max}</div>
                <div>Legelső hiányzó:{firstMissingAnswer}</div> */}
                <div className={classes.root}>
                    {/* {loading ? 'Loading...' : ''} */}
                    <div className={classes.questionPanel}>
                        <h2>{activeFilledQuestinnare.title}</h2>
                        {/* {this.props.match.params.id}{this.props.filledquestionnaires ? this.props.filledquestionnaires.length : '-'} */}
                    </div>
                    {!activeFilledQuestinnare &&
                        <div className={classes.root}>
                            <div className={classes.root}>
                                <ExpansionPanel defaultExpanded>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls='panel1c-content'
                                        id='panel1c-header'
                                    >
                                        <h2>{activeQuestinnare.title}</h2>

                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className={classes.details}>
                                        <div>{activeQuestinnare.description}</div>

                                    </ExpansionPanelDetails>
                                    <Divider />
                                    <ExpansionPanelActions>
                                        <Button
                                            variant='contained'
                                            className={classes.button}
                                            onClick={() => {
                                                history.push(`/`);
                                            }}
                                        >
                                            Mégse
                                        </Button>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            startIcon={<CreateIcon />}
                                            onClick={addFilledQuestionnaire}
                                        >
                                            Kérdőív kitöltésének elkezdése
                                        </Button>
                                    </ExpansionPanelActions>

                                </ExpansionPanel>
                            </div>
                        </div>
                    }
                    {activeFilledQuestinnare &&
                        <div>
                            {!activeFilledQuestinnare.gdpr &&
                                <Gdpr
                                    fill={activeFilledQuestinnare}
                                    key={1}
                                    questionnaires={activeQuestinnare}
                                    acceptGdpr={acceptGdpr}
                                    classes={classes}
                                    history={history}
                                />
                            }
                            {activeFilledQuestinnare.gdpr && !Object.keys(activeFilledQuestinnare.personal).length &&
                                <Personal
                                    fill={activeFilledQuestinnare}
                                    key={1}
                                    questionnaires={activeQuestinnare}
                                    acceptPersonal={acceptPersonal}
                                    classes={classes}
                                    meta={{ institutions: institutions }}
                                    history={history}
                                />
                            }
                            {activeFilledQuestinnare.gdpr && Object.keys(activeFilledQuestinnare.personal).length && activeFilledQuestinnare.status != 'evaluated' &&
                                <Questions
                                    loading={loading}
                                    fill={activeFilledQuestinnare}
                                    key={1}
                                    // questionnaire={this.props.questionnaires}
                                    updateAnswers={updateAnswer}
                                    questionnaire={activeQuestinnare ? activeQuestinnare : null}
                                    classes={classes}
                                    firstMissingAnswer={firstMissingAnswer}
                                    updateEvaluations={updateEvaluations}
                                    finishEvaluations={finishEvaluations}
                                // signOut={this.props.signOut}
                                />
                            }
                            {activeFilledQuestinnare.status == 'evaluated' &&
                                <Summary
                                    classes={classes}
                                    filled={activeFilledQuestinnare}
                                    questionnaire={activeQuestinnare}
                                    updateEvaluations={updateEvaluations}
                                />
                            }
                        </div>
                    }

                </div>
            </Box>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        questionnaires: state.firestore.ordered.questionnaires,
        filledquestionnaires: state.firestore.ordered.filledquestionnaires,
        sampling: state.firestore.ordered.sampling,
        authStatus: state.firebase.auth,
        userStatus: state.firebase.auth,
        users: state.firestore.ordered.users,
        updateStatus: state.questionnaire.update,
        institutions: state.firestore.ordered.institutions,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateGdpr: (e, id) => dispatch(updateGdpr(e, id)),
        finishEvaluations: (id) => dispatch(finishEvaluations(id)),
        updatePersonal: (e, id) => dispatch(updatePersonal(e, id)),
        updateAnswers: (e, id) => dispatch(updateAnswers(e, id)),
        addFilledquestionnaire2: (filledquestionnaire) => dispatch(addFilledquestionnaire(filledquestionnaire)),
        updateEvaluations: (evaluations, id) => dispatch(updateEvaluations(evaluations, id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sampling);
