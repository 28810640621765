import firebase from 'firebase/app';
import * as actions from './actionTypes';

export const addFilledquestionnaire = (filledquestionnaire) => async (dispatch, getState) => {

  try {

    dispatch({ type: actions.ADD_TODO_START });

    const uid = getState().firebase.auth.uid;
    const firestore = firebase.firestore();
    await firestore.collection('filledquestionnaires').add({
      ...filledquestionnaire,
      authorId: uid,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
    dispatch({ type: actions.ADD_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.ADD_TODO_FAIL, err });
  }
};

export const addFilledquestionnaire2 = (filledquestionnaire) => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    const authorId = getState().firebase.auth.uid
    firestore
      .collection("xxx")
      .add({
        ...filledquestionnaire,
        authorId: authorId,
        date: new Date(),
      })
      .then(() => {
        //   dispatch({
        //     type: "ADD_TASK",
        //     'task',
        //   });
      })
      .catch((err) => {
        dispatch({
          type: "ADD_TASK_ERR",
          err,
        });
      });
  };
};

export const updateFilledquestionnaire = (filledquestionnaire, id) => async (dispatch) => {
  try {
    dispatch({ type: actions.UPDATE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection('filledquestionnaires').doc(id).update({
      title: filledquestionnaire.title,
      description: filledquestionnaire.description,
      gdpr: filledquestionnaire.gdpr,
      personal: filledquestionnaire.personal,
      answer: filledquestionnaire.answer,
      version: filledquestionnaire.version,
      filledquestionnaire: filledquestionnaire.filledquestionnaire,
      state: filledquestionnaire.state,
      updatedAt: new Date(),
    });
    dispatch({ type: actions.UPDATE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.UPDATE_TODO_FAIL, err });
  }
};

export const updateGdpr = (state, id) => async (dispatch) => {
  try {
    dispatch({ type: actions.UPDATE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection('filledquestionnaires').doc(id).update({
      gdpr: state,
      status: 'gdpr',
      updatedAt: new Date(),
    });
    dispatch({ type: actions.UPDATE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.UPDATE_TODO_FAIL, err });
  }
};

export const updatePersonal = (personal, id) => async (dispatch) => {
  try {
    dispatch({ type: actions.UPDATE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection('filledquestionnaires').doc(id).update({
      personal: personal,
      institutions: personal[5] ? personal[5] : [],
      status: 'personal',
      updatedAt: new Date(),
    });
    dispatch({ type: actions.UPDATE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.UPDATE_TODO_FAIL, err });
  }
};

export const updateAnswers = (answers, id) => async (dispatch) => {
  try {
    dispatch({ type: actions.UPDATE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection('filledquestionnaires').doc(id).update({
      answers: answers,
      status: 'in progress',
      updatedAt: new Date(),
    });
    dispatch({ type: actions.UPDATE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.UPDATE_TODO_FAIL, err });
  }
};

export const deleteFilledquestionnaire = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.DELETE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection('filledquestionnaires').doc(id).delete();
    dispatch({ type: actions.DELETE_TODO_SUCESS });
  } catch (err) {
    dispatch({ type: actions.DELETE_TODO_FAIL, err });
  }
};

export const updateEvaluations = (evaluations, id) => async (dispatch) => {
  try {
    dispatch({ type: actions.UPDATE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection('filledquestionnaires').doc(id).update({
      evaluations: evaluations,
      status: 'evaluated',
      updatedAt: new Date(),
    });
    dispatch({ type: actions.UPDATE_TODO_SUCESS });
  } catch (err) {
    console.log(err);
    dispatch({ type: actions.UPDATE_TODO_FAIL, err });
  }
};
export const finishEvaluations = (id) => async (dispatch) => {
  try {
    dispatch({ type: actions.UPDATE_TODO_START });
    const firestore = firebase.firestore();
    await firestore.collection('filledquestionnaires').doc(id).update({
      status: 'finished',
      updatedAt: new Date(),
    });
    dispatch({ type: actions.UPDATE_TODO_SUCESS });
  } catch (err) {
    console.log(err);
    dispatch({ type: actions.UPDATE_TODO_FAIL, err });
  }
};