import React, { Component } from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PersonalField from './PersonalField';
import convertArrayByField from '../../common/utils';
class Personal extends Component {
    constructor(props) {
        super();
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeArray = this.handleChangeArray.bind(this);
    }
    state = {
        val: [],
        errors: {}
    }
    handleChange(e) {
        let n = this.state.val;
        n[e.target.name] = e.target.value;
        this.setState({
            val: { ...n }
        });
        let ee = this.state.errors;
        ee[e.target.name] = e.target.value ? '' : 'Nem lehet üres';
        this.setState({
            errors: ee
        });

    }
    handleChangeArray(name, value) {
        let n = this.state.val;
        let old = this.state.val[name] ?? []
        old = value;
        n[name] = old;
        this.setState({
            val: { ...n }
        });
        let ee = this.state.errors;
        ee[name] = value.length > 0 ? '' : 'Nem lehet üres';
        this.setState({
            errors: ee
        });

    }
    sortByKey(array, key) {
        return array.sort(function (a, b) {
            var x = a[key]; var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }
    onSubmit1() {
        let e = [];
        let err = 0;
        let x = convertArrayByField(this.props.questionnaires.personal, 'order').map((form0, index) => {
            let form = form0[0];
            if (form.type === 5) {
                e[form.id] = this.state.val[form.id] && this.state.val[form.id].length > 0 ? '' : 'Nem lehet üres';
                err = (this.state.val[form.id] && this.state.val[form.id].length > 0) || !form.required ? err : err + 1;
                return this.state.val[form.id] ? true : false
            } else {
                e[form.id] = this.state.val[form.id] ? '' : 'Nem lehet üres';
                err = this.state.val[form.id] || !form.required ? err : err + 1;
                return this.state.val[form.id] ? true : false
            }

        })
        this.setState({
            errors: e
        });
        if (err === 0)
            this.props.acceptPersonal(this.state.val, this.props.fill)
    };
    render() {
        const { classes, meta } = this.props;
        return (
            <div className={classes.root}>
                <form>
                    <ExpansionPanel defaultExpanded>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1c-content'
                            id='panel1c-header'
                        >
                            <div className={classes.column}>
                                <Typography noWrap display='block' >
                                    <h1>Adatok</h1>
                                </Typography>
                            </div>
                        </ExpansionPanelSummary>
                        <div className={classes.column}>
                            <ExpansionPanelDetails >
                                <Typography>
                                    {this.props.questionnaires &&
                                        convertArrayByField(this.props.questionnaires.personal, 'order').map((form, index) =>
                                            <div key={index}>
                                                <PersonalField
                                                    val={this.state.val}
                                                    form={form[0]}
                                                    classes={classes}
                                                    meta={meta}
                                                    handleChange={this.handleChange}
                                                    handleChangeArray={this.handleChangeArray}
                                                    errors={this.state.errors[form[0].id] ? this.state.errors[form[0].id] : ''}
                                                />
                                            </div>
                                        )}
                                </Typography>
                            </ExpansionPanelDetails>
                        </div>
                        <Divider />
                        <ExpansionPanelActions>
                            <Button
                                variant='contained'
                                className={classes.button}
                                onClick={() => {
                                    this.props.history.push(`/`);
                                }}
                            >
                                Mégse
                            </Button>
                            <Button
                                variant='contained'
                                className={classes.button}
                                color='primary'
                                onClick={(e) => { this.onSubmit1(e) }}
                            >
                                Mentés
                            </Button>
                        </ExpansionPanelActions>
                    </ExpansionPanel>
                </form>
            </div>
        );
    }
}

export default Personal;