import * as actions from '../actions/actionTypes';

/*
 * Separating the state for adding, updating and deleting todos for better usability and future improvements.
 */

const initState = {
  error: null,
  loading: false,
  update: {
    error: null,
    loading: false,
  },
  delete: {
    error: null,
    loading: false,
  },
};

const addTodoStart = (state) => {
  return {
    ...state,
    loading: true,
  };
};

const addTodoSucess = (state) => {
  return {
    ...state,
    error: false,
    loading: false,
  };
};

const addTodoFail = (state, payload) => {
  return {
    ...state,
    error: true,
    loading: false,
  };
};

const updateTodoStart = (state) => {
  return {
    ...state,
    update: {
      ...state.update,
      loading: true,
    },
  };
};

const updateTodoSucess = (state) => {
  return {
    ...state,
    update: {
      ...state.update,
      error: false,
      loading: false,
    },
  };
};

const updateTodoFail = (state, payload) => {
  return {
    ...state,
    update: {
      ...state.update,
      error: true,
      loading: false,
    },
  };
};

const deleteTodoStart = (state) => {
  return {
    ...state,
    delete: {
      ...state.delete,
      loading: true,
    },
  };
};

const deleteTodoSucess = (state) => {
  return {
    ...state,
    delete: {
      ...state.delete,
      error: false,
      loading: false,
    },
  };
};

const deleteTodoFail = (state, payload) => {
  return {
    ...state,
    delete: {
      ...state.delete,
      error: true,
      loading: false,
    },
  };
};

const todoReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actions.ADD_TODO_START:
      return addTodoStart(state);

    case actions.ADD_TODO_SUCESS:
      return addTodoSucess(state);

    case actions.ADD_TODO_FAIL:
      return addTodoFail(state, payload);

    case actions.UPDATE_TODO_START:
      return updateTodoStart(state);

    case actions.UPDATE_TODO_SUCESS:
      return updateTodoSucess(state);

    case actions.UPDATE_TODO_FAIL:
      return updateTodoFail(state, payload);

    case actions.DELETE_TODO_START:
      return deleteTodoStart(state);

    case actions.DELETE_TODO_SUCESS:
      return deleteTodoSucess(state);

    case actions.DELETE_TODO_FAIL:
      return deleteTodoFail(state, payload);

    default:
      return state;
  }
};

export default todoReducer;
