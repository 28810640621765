import React from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const EvaluationApi = (params) => {
    const { classes, filled, questionnaire } = params;
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="customTooltip">
                    <p className="tooltip_title">{`${payload[0].payload.validity_title} : ${payload[0].value} ${payload[0].payload.validity_name}`}</p>
                    <p className="desc">{`${label} : ${payload[0].payload.validity_text}`}</p>
                </div>
            );
        }

        return null;
    };
    const barColors = { verylow: "#e74c3c", low: "#f39c12", normal: "#01FF70", high: "#f39c12", veryhigh: "#e74c3c" };
    const barName = { verylow: "Nagyon alacsony", low: "Alacsony", normal: "Normál", high: "Magas", veryhigh: "Nagyon magas" };
    console.log(params);
    let questionNumber = 0;
    let questionList = [];

    if (questionnaire.questionnaire) {
        questionnaire.questionnaire.forEach(sections => (
            sections.children.forEach(question => (
                questionList[question.id] = question.name
            ))
        ));
        questionNumber = Object.keys(questionList).pop();
    }

    let personal = [];
    if (questionnaire.personal) {
        questionnaire.personal.forEach(p => (
            personal[p.id] = p
        ));
    }
    let data = [];
    if (filled && filled.evaluations && filled.evaluations.base_scales) {
        Object.keys(filled.evaluations.base_scales).forEach(q => {
            data.push({
                name: filled.evaluations.base_scales[q].name,
                value: filled.evaluations.base_scales[q].value,
                variance: filled.evaluations.base_scales[q].variance,
                standard: filled.evaluations.base_scales[q].standard,
                validity: filled.evaluations.base_scales[q].validity,
                validity_name: barName[filled.evaluations.base_scales[q].validity],
                validity_title: filled.evaluations.base_scales[q].validity_title,
                validity_text: filled.evaluations.base_scales[q].validity_text,
            });
        }
        );
    };
    return (
        <div>
            <h2>Kiértékelés</h2>
            {questionnaire.scales.api &&
                <div>
                    <h2>Adatok</h2>
                    {filled && filled.personal && personal.length > 0 &&
                        Object.keys(filled.personal).map((key) => {
                            return <div>
                                {personal[key].label}:
                                {(personal[key].type == 2 || personal[key].type == 4) &&
                                    filled.personal[key]
                                }
                                {personal[key].type == 3 &&
                                    personal[key].values.filter(x => x.value == filled.personal[key])[0].label
                                }
                            </div>
                        })
                    }

                    <h2>Kiértékelés</h2>
                    <h3>Validitás</h3>
                    {filled && filled.evaluations && filled.evaluations.validity_scales && filled.evaluations.validity_scales.subscale &&
                        Object.keys(filled.evaluations.validity_scales.subscale).map((key) => (
                            < div >
                                <div>
                                    {filled.evaluations.base_scales[key].validity_title}:
                                    <span className={filled.evaluations.validity_scales.subscale[key].evaluation === 'normal'
                                        ?
                                        classes.containedGreen
                                        :
                                        classes.containedRed}>
                                        {barName[filled.evaluations.validity_scales.subscale[key].evaluation]}
                                    </span>
                                </div>

                            </div>
                        ))
                    }
                    <h2>Skálák</h2>
                    {filled && filled.evaluations && Array.isArray(questionnaire.scales) &&
                        Object.keys(filled.evaluations).map((key) => (
                            <div>
                                <div className={classes.containedGreen}>
                                    {questionnaire.scales.filter(x => x.id == key)[0].name}: {filled.evaluations[key].evaluation.name} ({filled.evaluations[key].value})
                                </div>
                                {
                                    Object.keys(filled.evaluations[key].sub_scales).map((key2) => {
                                        return <div>
                                            {questionnaire.scales.filter(x => x.id == key)[0].children.filter(x => x.id == key2)[0].name}: {key2}
                                        </div>
                                    })
                                }
                            </div>
                        ))
                    }
                    {data &&
                        <BarChart
                            width={800}
                            height={300}
                            data={data}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                            <Bar dataKey="value" fill="#82ca9d">
                                {
                                    data.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={barColors[entry.validity]} />
                                    ))
                                }
                            </Bar>
                        </BarChart>
                    }
                    {filled && filled.evaluations && filled.evaluations.scales && !Array.isArray(questionnaire.scales) &&
                        Object.keys(filled.evaluations.base_scales).map((key) => {
                            console.log(filled.evaluations.base_scales[key].name)
                            return <div>
                                <h3>
                                    {filled.evaluations.base_scales[key].validity_title}
                                </h3>
                                {key}: {filled.evaluations.base_scales[key].value}
                                <div>
                                    {filled.evaluations.base_scales[key].validity_text}
                                </div>
                            </div>
                        })

                    }
                </div>
            }
        </div >
    );
};

export default EvaluationApi;