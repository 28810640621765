import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';
import { useHistory } from 'react-router-dom';

const QuestionairesTable = (params) => {
    const { classes, filled, questionnaires, sampling } = params;
    const history = useHistory();
    return (
        <div>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Teszt</TableCell>
                            <TableCell align="right">Kitöltött</TableCell>
                            <TableCell align="right">Mintavételek</TableCell>
                            <TableCell align="right">Státusz</TableCell>
                            <TableCell align="right">...</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {questionnaires.map((questionnaire, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {questionnaire.title}{questionnaire.id}
                                </TableCell>
                                <TableCell component="th" scope="row">

                                    {filled && filled[questionnaire.id] &&
                                        filled[questionnaire.id].map((f) => {
                                            return <div>{f.id}</div>;
                                        })

                                    }
                                </TableCell>
                                <TableCell component="th" scope="row">

                                    {sampling && sampling[questionnaire.id] &&
                                        sampling[questionnaire.id].map((f) => {
                                            return <div>{f.id}</div>;
                                        })

                                    }
                                </TableCell>
                                <TableCell align="right">{questionnaire.state}</TableCell>
                                <TableCell align="right">

                                    <Button
                                        variant='contained'
                                        color='primary'
                                        className={classes.button}
                                        startIcon={<CreateIcon />}
                                        onClick={() => {
                                            history.push(`/fill/${questionnaire.id}`);
                                        }}
                                    >
                                        Kitöltés
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default QuestionairesTable;