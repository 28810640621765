import React from 'react';
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';
import { useHistory } from 'react-router-dom';
import { MobileStepper, Step, StepButton, Stepper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardArrowLeftOutlined, KeyboardArrowRightOutlined } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const Evaluation = (params) => {
    const { classes, filled, questionnaire } = params;
    return (
        <div>
            <h2>Kiértékelés</h2>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Skála</TableCell>
                            <TableCell align="center">Értékelés</TableCell>
                            <TableCell align="center">Eredmény</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {questionnaire.scales.api ?
                            'API'
                            :
                            Object.keys(filled.evaluations).map((key, index) => (
                                <TableRow key={index}>
                                    <TableCell className={classes.containedGreen}>
                                        <h2>
                                            {questionnaire.scales.filter(x => x.id == key)[0].name}
                                        </h2>
                                        {questionnaire.scales.filter(x => x.id == key)[0].description}
                                    </TableCell>
                                    <TableCell className={classes.evaluationDescription}>
                                        <h2>
                                            {filled.evaluations[key].evaluation.value} . szint {filled.evaluations[key].evaluation.name}
                                        </h2>
                                        {questionnaire.levels[filled.evaluations[key].evaluation.value - 1]}
                                    </TableCell>
                                    <TableCell className={classes.evaluationDescription}>
                                        <div>{filled.evaluations[key].value} pont</div>
                                        {
                                            Object.keys(filled.evaluations[key].sub_scales).map((key2) => {
                                                return <div >
                                                    {questionnaire.scales.filter(x => x.id == key)[0].children.filter(x => x.id == key2)[0].name}: {filled.evaluations[key].sub_scales[key2].value} pont
                                                </div>
                                            })
                                        }
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default Evaluation;