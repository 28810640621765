import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { } from 'react-redux-firebase';
import { updateGdpr, updatePersonal, addFilledquestionnaire, updateAnswers } from '../../../store/actions/filledQuestionnaireActions';
import { addQuestionnaire } from '../../../store/actions/questionnaireActions';
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';
import firebase from 'firebase/app';
import Gdpr from './Gdpr';
import Personal from './Personal';
import { green, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Questions from './Questions';
import { signOut } from '../../../store/actions/authActions';
class FillQuestionnaire extends Component {
    constructor(props) {
        super(props);
        // props.addFilledquestionnaire2({});
        this.acceptGdpr = this.acceptGdpr.bind(this);
        this.acceptPersonal = this.acceptPersonal.bind(this);
        this.updateAnswers = this.updateAnswers.bind(this);
    }
    addItem = (e) => {
        const firestore = firebase.firestore;
        e.preventDefault();
        this.props.addFilledquestionnaire2({
            docId: this.props.match.params.id,
            title: this.props.questionnaires[0].title,
            status: 'Kérdőív kitöltésének elkezdése',
            gdpr: false,
            personal: [],
            answers: []
        });

        firestore().collection("filledquestionnaires")
            .where(
                'authorId', '==', this.props.uid
                //  ['docId', '==',this.props.match.params.id]

            )
            // ['user.uid', '==', ownProps.uid],
            // ['authorId', '==', ownProps.uid],
            // ['docId', '==', ownProps.match.params.id],
            .get()
            .then(snapshot => {
                // snapshot.forEach(doc => {
                //   const data = doc.data();
                // });
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }

    acceptGdpr = (fill) => {
        this.props.updateGdpr(true, fill.id);
    }

    acceptPersonal = (val, fill) => {
        this.props.updatePersonal(val, fill.id);
    }

    updateAnswers = (val, fill) => {
        this.props.updateAnswers(val, fill.id);
    }

    render() {
        const { classes, authStatus, updateStatus } = this.props;
        const { isEmpty, isLoaded } = authStatus;
        const { loading } = updateStatus;
        let fillQ = this.props.filledquestionnaires && this.props.filledquestionnaires.length === 0;
        return (
            <div className={classes.root}>
                {/* {loading ? 'Loading...' : ''} */}
                <div className={classes.questionPanel}>
                    <h2>{this.props.questionnaires && this.props.questionnaires[0].title}</h2>
                    {/* {this.props.match.params.id}{this.props.filledquestionnaires ? this.props.filledquestionnaires.length : '-'} */}
                </div>
                {fillQ &&
                    <Button
                        variant='contained'
                        color='primary'
                        startIcon={<CreateIcon />}
                        onClick={this.addItem}
                    >
                        Kérdőív kitöltésének elkezdése
                    </Button>
                }
                {this.props.filledquestionnaires && this.props.filledquestionnaires.map((fill, index) =>
                    <div key={index}>
                        {/* <Button
                            variant='contained'
                            color='primary'
                            key='default${index}'
                            startIcon={<CreateIcon />}
                            onClick={this.addItem}
                        >
                            {fill.title}- {fill.status}
                        </Button> */}
                        {!fill.gdpr &&
                            <Gdpr
                                fill={fill}
                                key={index}
                                questionnaires={this.props.questionnaires}
                                acceptGdpr={this.acceptGdpr}
                                classes={classes}
                            />
                        }
                        {fill.gdpr && !Object.keys(fill.personal).length &&
                            <Personal
                                fill={fill}
                                key={index}
                                questionnaires={this.props.questionnaires}
                                acceptPersonal={this.acceptPersonal}
                                classes={classes}
                            />
                        }
                        {fill.gdpr && Object.keys(fill.personal).length &&
                            <Questions
                                loading={loading}
                                fill={fill}
                                key={index}
                                // questionnaire={this.props.questionnaires}
                                updateAnswers={this.updateAnswers}
                                questionnaire={this.props.questionnaires ? this.props.questionnaires[0] : null}
                                classes={classes}
                                signOut={this.props.signOut}
                            />
                        }
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        questionnaires: state.firestore.ordered.questionnaires,
        filledquestionnaires: state.firestore.ordered.filledquestionnaires,
        authStatus: state.firebase.auth,
        uid: state.firebase.auth.uid,
        updateStatus: state.questionnaire.update,
        // questionnairesInfo: state.firestore.ordered.questionnaires && ownProps.match && ownProps.match.params && ownProps.match.params.id ? state.firestore.ordered.questionnaires[ownProps.match.params.id]: {},
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateGdpr: (e, id) => dispatch(updateGdpr(e, id)),
        updatePersonal: (e, id) => dispatch(updatePersonal(e, id)),
        updateAnswers: (e, id) => dispatch(updateAnswers(e, id)),
        addFilledquestionnaire2: (filledquestionnaire) => dispatch(addFilledquestionnaire(filledquestionnaire)),
        addQuestionnaire: (questionnaire) => dispatch(addQuestionnaire(questionnaire)),
        signOut: () => dispatch(signOut()),
    };
};

// export default connect(mapStateToProps, mapDispatchToProps)(FillQuestionnaire);

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((ownProps, state) => [
        {
            collection: "filledquestionnaires",
            // where: ["authorId", "==", ownProps.uid]
            where: [
                ['authorId', '==', ownProps.uid],
                // ['authorId', '==', ownProps.uid],
                ['id', '==', ownProps.match.params.id],

            ],
            // orderBy: ["provider_eqalm_id", "asc"],
        },
        {
            collection: "questionnaires",
            doc: ownProps.match.params.id //'2XUJ9t7zegsFlbDvv4Er'
            // where: ["authorId", "==", ownProps.uid]
            //  where: [['id', "<>", ownProps.match.params.id]],
            // orderBy: ["provider_eqalm_id", "asc"],
        },
    ])
)(FillQuestionnaire);