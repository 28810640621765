import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

class Guide  extends Component {
    render() {
        const { classes, text } = this.props;
        return (
            <Dialog
                fullWidth={true}
                open={this.props.open}
                // onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                   {<div dangerouslySetInnerHTML={{__html: text}} />}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            this.props.handleHideGuide()
                        }}
                        color="primary"
                        variant="contained"
                        autoFocus
                    >
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default Guide;